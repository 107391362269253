//bootsrtap v5
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1600px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1248px,
  xxl: 1440px
);
$container-padding-x:        24px;
$grid-gutter-width:          24px;
$mobile-container-padding-x: 16px;
$mobile-grid-gutter-width:   16px;

$xxl-field: calc((100vw - #{map-get($container-max-widths, xxl) - $container-padding-x * 2})/2);
$xxl-field-negative: calc((-100vw + #{map-get($container-max-widths, xxl) - $container-padding-x * 2})/2);

$xl-field: calc((100vw - #{map-get($container-max-widths, xl) - $container-padding-x * 2})/2);
$xl-field-negative: calc((-100vw + #{map-get($container-max-widths, xl) - $container-padding-x * 2})/2);

$lg-field: calc((100vw - #{map-get($container-max-widths, lg) - $container-padding-x * 2})/2);
$lg-field-negative: calc((-100vw + #{map-get($container-max-widths, lg) - $container-padding-x * 2})/2);

$md-field: calc((100vw - #{map-get($container-max-widths, md) - $container-padding-x * 2})/2);
$md-field-negative: calc((-100vw + #{map-get($container-max-widths, md) - $container-padding-x * 2})/2);

$sm-field: calc((100vw - #{map-get($container-max-widths, sm) - $container-padding-x * 2})/2);
$sm-field-negative: calc((-100vw + #{map-get($container-max-widths, sm) - $container-padding-x * 2})/2);

$font1: 'Montserrat', sans-serif;
$font2: 'Open Sans', sans-serif;

$trs1: .25s ease-in-out;
$trs2: .5s ease-in-out;
$trs3: 1s ease-in-out;

$trs1out: .25s ease-out;
$trs2out: .5s ease-out;
$trs3out: 1s ease-out;

$trs1in: .25s ease-in;
$trs2in: .5s ease-in;
$trs3in: 1s ease-in;
