//variables
@import "./components/variables";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";

.preloader {
  background-color: var(--color-bg);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100000;
  top: 0;
  left: 0;
  display: flex;
  font-size: 0.55vw;
  transition: opacity .5s ease-in-out, visibility .5s ease-in-out;
  &.active {
    opacity: 1 !important;
  }
  &__char,
  img {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    &.active {
      opacity: 1;
    }
  }
  &__center {
    margin: auto;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 2.3em;
  }
  &__text {
    height: 4em;
    position: relative;
    z-index: 3;
  }
  &__square {
    opacity: 0;
    position: absolute;
    background-color: #F7955B;
    left: 0;
    height: 7em;
    width: 7.5em;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    transition: opacity 1s ease-in-out;
    &.active {
      opacity: 1;
    }
  }
  @include media-breakpoint-down(xxl) {
    font-size: 0.65vw;
  }
  @include media-breakpoint-down(lg) {
    font-size: 0.95vw;
  }
  @include media-breakpoint-down(sm) {
    font-size: 3.5vw;
    .st1 {
      display: none;
    }
    &__center {
      width: 23vw;
      height: 22vw;
      padding: 0;
      overflow: hidden;
    }
    &__square {
      width: 100%;
      height: 100%;
      top: 0;
      transform: translate(0);
    }
    &__text {
      position: absolute;
      left: 5.1vw;
    }
  }
}

@keyframes girl {
  0% { 
    transform: rotate(0deg) translateX(2px) rotate(0deg);
  }
  30% {
    transform: rotate(30deg) translateX(2px);
  }
  100%   { 
    transform: rotate(360deg) translateX(2px) rotate(-360deg); 
  }
}